exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-index-tsx": () => import("./../../../src/pages/notes/index.tsx" /* webpackChunkName: "component---src-pages-notes-index-tsx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-end-of-an-era-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/end-of-an-era/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-end-of-an-era-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-fine-grained-controls-and-why-we-dont-need-it-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/fine-grained-controls-and-why-we-dont-need-it/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-fine-grained-controls-and-why-we-dont-need-it-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-mic-check-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/mic-check/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-mic-check-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-notes-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/notes/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-notes-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-qubi-research-postmortem-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/qubi-research-postmortem/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-qubi-research-postmortem-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-functional-software-deployment-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/spice-up-with-nix-functional-software-deployment/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-functional-software-deployment-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-scripts-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/spice-up-with-nix-scripts/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-scripts-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-shells-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/spice-up-with-nix-shells/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-up-with-nix-shells-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-with-nix-traditional-software-deployment-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/spice-with-nix-traditional-software-deployment/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-spice-with-nix-traditional-software-deployment-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-surviving-docker-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/surviving-docker/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-surviving-docker-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-writings-index-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/notes/writings/index.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-notes-writings-index-mdx" */),
  "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-src-content-about-mdx": () => import("./../../../src/pages/notes/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/about.mdx" /* webpackChunkName: "component---src-pages-notes-mdx-frontmatter-slug-tsx-content-file-path-src-content-about-mdx" */)
}

